import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import * as Clickable from "components/clickable"
import OfferDetailsView from "components/survey/offers/offer-details-view"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { apis as API } from "@sog/sdk"

export default function OfferDetails(props) {
  const inputJson = props.location.state.inputJson
  const [repaymentValue, setRepaymentValue] = useState(inputJson.monthly_repayment)
  const [spendValue, setSpendValue] = useState(inputJson.monthly_spend)
  const [balanceValue, setBalanceValue] = useState(inputJson.balance_remaining)

  const repayment = ["Always pay off in full", "Almost always pay off in full", "Sometimes pay off in full", "Never pay off in full", "Paying off a balance (no spend)"]

  const spend = ["$0", "up to $2,500", "$2,500 to $5,000", "$5,000 to $10,000", "$10,000 to $15,000", "$15,000 to $20,000", "$20,000 or more"]

  const balance = ["up to $2,500", "$2,500 to $5,000", "$5,000 to $10,000", "$10,000 to $15,000", "$15,000 to $20,000", "$20,000 or more"]

  const filters = () => {
    return (
      <div>
        <Typography.P comp={"small-bold"}>Repayment</Typography.P>
        <Select value={repaymentValue} style={{ marginTop: "15px", width: "100%", backgroundColor: "#F7FAFA" }} onChange={e => setRepaymentValue(e.target.value)}>
          {repayment.map((v, i) => {
            return <MenuItem value={i + 1}>{v}</MenuItem>
          })}
        </Select>
        <Typography.P comp={"small-bold"} style={{ marginTop: "40px" }}>
          Spend
        </Typography.P>
        <Select value={spendValue} style={{ marginTop: "15px", width: "100%", backgroundColor: "#F7FAFA" }} onChange={e => setSpendValue(e.target.value)}>
          {spend.map((v, i) => {
            return <MenuItem value={i + 1}>{v}</MenuItem>
          })}
        </Select>
        <Typography.P comp={"small-bold"} style={{ marginTop: "40px" }}>
          Balance
        </Typography.P>
        <Select value={balanceValue} style={{ marginTop: "15px", width: "100%", backgroundColor: "#F7FAFA" }} onChange={e => setBalanceValue(e.target.value)}>
          {balance.map((v, i) => {
            return <MenuItem value={i + 1}>{v}</MenuItem>
          })}
        </Select>
        <Clickable.Text
          comp={6}
          style={{ height: "56px", width: "100%", marginTop: "40px" }}
          disabled={false}
          onClick={() => {
            inputJson.monthly_repayment = repaymentValue
            inputJson.monthly_spend = spendValue
            inputJson.balance_remaining = balanceValue
            API.getOfferDetailsQA(inputJson, responseJson => {
              navigate("/offer-qa/offer-details", {
                state: {
                  inputJson: inputJson,
                  responseJson: responseJson,
                },
              })
            })
          }}
        >
          Apply card usage
        </Clickable.Text>
      </div>
    )
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}>
      <OfferDetailsView {...props} isQA={true} />
      <div style={{ width: "20%", maxWidth: "260px", marginLeft: "100px" }}>{filters()}</div>
    </div>
  )
}
