import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { apis as API } from "@sog/sdk"
import store from "state/store"
import * as styles from "./style.module.scss"

export default function SelectCard(props) {
  const state = store.getState()
  const providers = state.main.provider && props.location.state ? state.main.provider.filter(item => props.location.state.providers.includes(item.id)) : []
  // const rewardProgram = state.main.rewardProgram

  const [providerID, setProviderID] = useState()
  // const [rewardsID, setRewardsID] = useState()

  // const rewardsListForProvider = providerID => {
  //   return Array.from(
  //     new Set(
  //       providers
  //         .find(x => x.id === providerID)
  //         .products.map(item => {
  //           return rewardProgram.find(x => x.id === item.rewardProgram)
  //         })
  //     )
  //   ).sort((a, b) => (a.id > b.id ? 1 : -1))
  // }

  const handleSubmit = () => {
    const jsonInput = {
      survey_id: "0",
      provider_id: providerID,
      // rewards_id: rewardsID,
    }

    API.getOffersQA(jsonInput, responseJson => {
    //  console.log("responseJson", responseJson)
      navigate("/offer-qa/offers", {
        state: {
          responseJson: responseJson,
          jsonInput: jsonInput,
        },
      })
    })
  }

  const cardDetail = () => {
    return (
      <div style={{ marginTop: "60px" }}>
        <Typography.P comp="survey-help-question">Card Provider</Typography.P>
        <Select style={{ marginTop: "15px", width: "100%", backgroundColor: "#F7FAFA" }} className={styles.selectMenuStyle} onChange={e => setProviderID(e.target.value)}>
          {providers.map((p, i) => {
            return <MenuItem value={p.id}>{p.name}</MenuItem>
          })}
        </Select>
        {/* {offerRewardProgram ? (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography.P comp="survey-help-question">Rewards Points</Typography.P>
            <Select style={{ marginTop: 5 }} labelID="rewards-label" onChange={e => setRewardsID(e.target.value)}>
              {offerRewardProgram.map((r, i) => {
                return <MenuItem value={r.id}>{r.value}</MenuItem>
              })}
            </Select>
          </div>
        ) : null} */}
        <Clickable.Text style={{ marginTop: 30 }} comp={3} className="survey-button" onClick={handleSubmit} disabled={!providerID}>
          Submit
        </Clickable.Text>
      </div>
    )
  }

  return (
    <div className="page-container2">
      <div className="survey-container">
        <div>
          <Typography.H3>Offer QA</Typography.H3>
          {cardDetail()}
        </div>
      </div>
    </div>
  )
}
