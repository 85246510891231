import * as Clickable from "components/clickable"
import ProductView from "components/survey/product-view"
import { Typography } from "components/typography"
import { navigate } from "gatsby"
import React from "react"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import AppManager from "services/app-manager"
import store from "state/store"
import * as styles from "./style.module.scss"

export default function Offers(props) {
  const state = store.getState()
  const reactStore = useStore()

  const jsonResponse = props.location.state.responseJson
  const jsonInput = props.location.state.jsonInput
  const rewardProgram = state.main.rewardProgram
  const providers = state.main.provider
  const provider = providers.find(x => x.id === jsonInput.provider_id)

  const customerType = ["Retention", "X-sell/New-to-bank"]
  const offerType = ["Standard", "Promoted", "Suppressed"]
  const offerAvailabilityType = ["Public", "Non-public"]
  const rewardProgramList = Array.from(
    new Set(
      [...jsonResponse.retention, ...jsonResponse.others].map(item => {
        return rewardProgram.find(x => x.id === item.rewardProgram)
      }),
    ),
  ).sort((a, b) => (a.id > b.id ? 1 : -1))
  const rewardProgramType = []
  rewardProgramList.map(r => rewardProgramType.push(r.id))

  if (!reactStore.selected) {
    reactStore.selected = {}
  }
  const [selectedCustomerType, setSelectedCustomerType] = React.useState(reactStore.selected["customerType"] ? reactStore.selected["customerType"] : [])
  const [selectedOfferType, setSelectedOfferType] = React.useState(reactStore.selected["offerType"] ? reactStore.selected["offerType"] : [])
  const [selectedOfferAvailabilityType, setSelectedOfferAvailabilityType] = React.useState(reactStore.selected["offerAvailabilityType"] ? reactStore.selected["offerAvailabilityType"] : [])
  const [selectedRewardProgramType, setSelectedRewardProgramType] = React.useState(reactStore.selected["rewardProgramType"] ? reactStore.selected["rewardProgramType"] : [])

  let retentionOffers = []
  let otherOffers = []

  if (!selectedCustomerType.length || selectedCustomerType.includes("Retention")) {
    retentionOffers = jsonResponse.retention
  }
  if (!selectedCustomerType.length || selectedCustomerType.includes("X-sell/New-to-bank")) {
    otherOffers = jsonResponse.others
  }
  // else {
  //   if (selectedCustomerType.includes("X-sell")) {
  //     otherOffers = jsonResponse.others.filter(x => x.s3 !== 0 || x.s4 !== 0)
  //   }
  //   if (selectedCustomerType.includes("New-to-bank")) {
  //     otherOffers = otherOffers.concat(jsonResponse.others.filter(x => x.s5 !== 0 || x.s6 !== 0))
  //   }
  // }

  const filterByRewardProgramType = offers => {
    return offers.filter(o => selectedRewardProgramType.some(r => r === o.rewardProgram))
  }

  const filterByOfferType = (offers, type) => {
    let results = []

    if (selectedOfferType.includes("Standard")) {
      if (type === "Retention") {
        results = results.concat(offers.filter(o => o.s1 === 1 || o.s2 === 1))
      } else {
        results = results.concat(offers.filter(o => o.s3 === 1 || o.s4 === 1 || o.s5 === 1 || o.s6 === 1))
      }
    }
    if (selectedOfferType.includes("Promoted")) {
      if (type === "Retention") {
        results = results.concat(offers.filter(o => o.s1 === 2 || o.s2 === 2))
      } else {
        results = results.concat(offers.filter(o => o.s3 === 2 || o.s4 === 2 || o.s5 === 2 || o.s6 === 2))
      }
    }
    if (selectedOfferType.includes("Suppressed")) {
      if (type === "Retention") {
        results = results.concat(offers.filter(o => o.s1 === 0 || o.s2 === 0))
      } else {
        results = results.concat(offers.filter(o => o.s3 === 0 || o.s4 === 0 || o.s5 === 0 || o.s6 === 0))
      }
    }

    return results
  }

  const filterByOfferAvailabilityType = offers => {
    let results = []

    if (selectedOfferAvailabilityType.includes("Public")) {
    //  console.log(offers.filter(o => o.public))
      results = results.concat(offers.filter(o => o.public))
      // console.log("results", results)
    }
    if (selectedOfferAvailabilityType.includes("Non-public")) {
      results = results.concat(offers.filter(o => !o.public))
    }

    return results
  }

  if (selectedRewardProgramType.length) {
    retentionOffers = filterByRewardProgramType(retentionOffers)
    otherOffers = filterByRewardProgramType(otherOffers)
  }

  if (selectedOfferType.length) {
    retentionOffers = filterByOfferType(retentionOffers, "Retention")
    otherOffers = filterByOfferType(otherOffers, "Others")
  }

  if (selectedOfferAvailabilityType.length) {
    retentionOffers = filterByOfferAvailabilityType(retentionOffers)
    otherOffers = filterByOfferAvailabilityType(otherOffers)
  }

  const listHeader = txt => (
    <div className={styles.listHeader}>
      <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", weight: 700, lineHeight: "40px", fontSize: "20px" }}>{txt}</div>
    </div>
  )

  const offerView = (g, o) => {
    return (
      <div>
        <ProductView product={o} isNameLong={true} isSchemeFb={true} />
        <div style={{ marginTop: "30px", marginBottom: "30px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          {(g.offerType === "Retention" || (g.offerType !== "Retention" && o.landingPageUrl !== null)) && o.active === "Y" ? (
            <Clickable.Text comp={6} className="offers-view-button" disabled={g === "Retention" ? true : false} onClick={() => AppManager.getInstance().getOffer(g.offerType, o.offerId, o, jsonInput.provider_id, provider.name)}>
              Go to offer
            </Clickable.Text>
          ) : (
            <div />
          )}
          <Clickable.Text
            // backgroundColor="#e5f7f9"
            // color="#00afc5"
            // selectedColor="#cceff3"
            secondary
            comp={6}
            className="offers-view-button"
            onClick={() => {
              const data = {
                provider_id: provider.id,
                offer_id: o.offerId,
                rewards_id: o.rewardProgram,
                monthly_repayment: 3,
                monthly_spend: 3,
                balance_remaining: 3,
              }
              API.getOfferDetailsQA(data, responseJson => {
                navigate("/offer-qa/offer-details", {
                  state: {
                    inputJson: data,
                    responseJson: responseJson,
                  },
                })
              })
            }}
          >
            Learn more
          </Clickable.Text>
        </div>
        <div style={{ marginBottom: "30px" }}>
          <div>{o.offerId}</div>
          <div>
            {o.s1}
            {o.s2} {o.s3}
            {o.s4} {o.s5}
            {o.s6}
          </div>
        </div>
      </div>
    )
  }

  const offerGroup = (g, offers) => {
    return (
      <div>
        {listHeader(g === "Retention" ? "Retention" : "X-sell/New-to-bank")}
        {offers.map((o, i) => {
          return offerView(g, o)
        })}
      </div>
    )
  }

  const offerGroups = () => {
    return (
      <div>
        {retentionOffers.length ? offerGroup("Retention", retentionOffers) : null}
        {otherOffers.length ? offerGroup("Others", otherOffers) : null}
      </div>
    )
  }

  const filterList = (list, selectedList, setSelectedList, type) => {
    return list.map(value => {
      return (
        <Clickable.View
          rootStyle={{
            height: "60px",
            width: "100%",
            borderBottom: "0.5px solid #eeeeee",
          }}
          onClick={() => {
            setSelectedList(oldArray => {
              if (selectedList.includes(value)) {
                const newArray = oldArray.filter(item => item !== value)
                reactStore.selected[type] = newArray
                return newArray
              } else {
                const newArray = [...oldArray, value]
                reactStore.selected[type] = newArray
                return newArray
              }
            })
          }}
        >
          <img src={selectedList.includes(value) ? "/images/checkbox_selected_true.png" : "/images/checkbox_selected_false.png"} style={{ width: "24px", height: "24px", marginLeft: "5px" }} alt="" />
          <div className={styles.listItemText}>
            <Typography.H6 comp="list-item" style={{ textAlign: "left" }}>
              {type !== "rewardProgramType" ? value : rewardProgram.find(r => r.id === value).value}
            </Typography.H6>
          </div>
        </Clickable.View>
      )
    })
  }

  const filters = () => {
    return (
      <div>
        <Typography.H6>Customer Type</Typography.H6>
        {filterList(customerType, selectedCustomerType, setSelectedCustomerType, "customerType")}
        <Typography.H6>Reward Program</Typography.H6>
        {filterList(rewardProgramType, selectedRewardProgramType, setSelectedRewardProgramType, "rewardProgramType")}
        <Typography.H6>Offer</Typography.H6>
        {filterList(offerType, selectedOfferType, setSelectedOfferType, "offerType")}
        <Typography.H6>Offer Availability</Typography.H6>
        {filterList(offerAvailabilityType, selectedOfferAvailabilityType, setSelectedOfferAvailabilityType, "offerAvailabilityType")}
        <Clickable.Text
          comp={6}
          style={{ height: "56px", width: "100%", marginTop: "30px" }}
          disabled={false}
          onClick={() => {
            setSelectedCustomerType([])
            setSelectedRewardProgramType([])
            setSelectedOfferType([])
            setSelectedOfferAvailabilityType([])
            reactStore.selected = {}
          }}
        >
          Clear filters
        </Clickable.Text>
      </div>
    )
  }

  return (
    // <OffersView {...props} isQA={true} />
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="survey-container">{offerGroups()}</div>
      <div style={{ width: "20%", marginTop: "31px", marginLeft: "100px" }}>{filters()}</div>
    </div>
  )
}
