import { useMediaQuery } from "@mui/material"
import * as Clickable from "components/clickable"
import { Typography } from "components/typography"
import { Link } from "gatsby"
import Product from "models/Product"
import CreditCardProduct from "models/product-models/CreditCardProduct"
import HomeLoanProduct from "models/product-models/HomeLoanProduct"
import PersonalLoanProduct from "models/product-models/PersonalLoanProduct"
import HomeLoanProvider from "models/provider-models/HomeLoanProvider"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import AppManager from "services/app-manager"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { rewardProgramImage } from "../../survey/utils"
import * as styles from "./style.module.scss"
import TermDepositProduct from "models/product-models/TermDepositProduct"
import AccountProduct from "models/product-models/AccountProduct"

const TickLine = ({ children, style, className, isOfferDetails, isTablet, isArticle }: { children?: any; style?: any; className?: any; isOfferDetails?: boolean; isTablet?: boolean; isArticle?: boolean }) => {
  return (
    <div className={className}>
      <div className={styles.offers} style={style}>
        {!isArticle ? (
          <div style={{ height: "23.5px", width: isOfferDetails ? "10%" : "8%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <LazyLoadImage src="/images/icon_green_tick.png" style={{width: "22.5px", height: "22.5px"}} alt="" />
          </div>
        ) : null}
        {!isArticle ? (
          <div style={{ height: "100%", marginLeft: isOfferDetails ? "10px" : "12.54px", width: "92%", display: "flex" }}>
            <Typography.P comp={isTablet ? "content-p-tablet" : "content-p"} textStyle={{display: "flex"}}>{children}</Typography.P>
          </div>
        ) : (
          <Typography.P comp={"product-detail"} textStyle={{display: "flex"}}>{children}</Typography.P>
        )}
      </div>
    </div>
  )
}

export const CreditCard = ({ product, learnMore, isOfferDetails, isTablet, isArticle, isTopPick }: { product: CreditCardProduct; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean; isArticle?: boolean; isTopPick?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  // TickList Hierarchy
  const tickList = []

  // Reward Points
  if (product.rewardProgramId !== null && product.rewardProgramId !== 0) {
    if (!isArticle) {
      if (product.directRewardId !== null) {
        const stringOne = product.rewardProgramPointsName
        const stringTwo = " via " + product.directRewardName
        tickList.push(
          <TickLine key={"rewardPoints"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
            {stringOne}
            <img className={styles.rewardImage} src={rewardProgramImage(product.rewardProgramId)} alt={product.rewardProgramName} title={product.rewardProgramName} />
            {stringTwo}*
          </TickLine>,
        )
      } else {
        const string = product.rewardProgramPointsName
        tickList.push(
          <TickLine key={"rewardPointsIndirect"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
            {string}
            <img className={styles.rewardImage} src={rewardProgramImage(product.rewardProgramId)} alt={product.rewardProgramName} title={product.rewardProgramName} />
          </TickLine>,
        )
      }
    }
  }

  // Interest Rates
  if (product.purchaseRate < 0.14) {
    if (product.purchaseRate > 0) {
      tickList.push(
        <TickLine key={"lowPurchase"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          Low ongoing purchase rate
        </TickLine>,
      )
    } else {
      tickList.push(
        <TickLine key={"interestFree"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          Interest free
        </TickLine>,
      )
    }
  }

  // Annual Fee
  if (product.annualFee < 40) {
    if (product.annualFee > 0) {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          Low ongoing annual fee
        </TickLine>,
      )
    } else {
      tickList.push(
        <TickLine key={"noAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          No annual fee
        </TickLine>,
      )
    }
  }

  // Annual Credit
  if (product.voucherDescription !== null) {
    if (product.annualVoucher !== null) {
      let string = `$${product.annualVoucher} ${product.voucherDescription} each year`
      tickList.push(
        <TickLine key={"annualVoucher"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          {string}
        </TickLine>,
      )
    } else {
      let string = `${product.voucherDescription} each year`
      tickList.push(
        <TickLine key={"onlyVoucher"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
          {string}
        </TickLine>,
      )
    }
  }

  // FX Fee
  if (product.fxFee === 0) {
    tickList.push(
      <TickLine key={"noFX"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
        No international transaction fees
      </TickLine>,
    )
  }

  // Insurance
  if (product.osTravelInsurance === "Y") {
    tickList.push(
      <TickLine key={"travelInsurance"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
        Complimentary travel insurance
      </TickLine>,
    )
  }

  // Airport Lounge
  if (product.loungePassDescription !== null) {
    tickList.push(
      <TickLine key={"loungePass"} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle}>
        {product.loungePassDescription}
      </TickLine>,
    )
  }

  if (tickList.length === 0 && isArticle) {
    return null
  }
  return <TickLines product={product} tickList={tickList} learnMore={learnMore} descriptiveText={product.cardHeading} isOfferDetails={isOfferDetails} isTablet={isTablet} isArticle={isArticle} isTopPick={isTopPick} />
}

/**
 * Renders key features for a Personal Loan with ticks
 *
 * @param {PersonalLoanProduct} product The product to render for
 * @param {boolean} [learnMore] (Optional) Whether to render the Learn More button (i.e. on the provider page)
 * @param {boolean} [isOfferDetails] (Optional) Whether to render for the Product page
 * @param {boolean} [isTablet] (Optional) Whether to render for a tablet view on the provider page
 * @returns
 */
export const PersonalLoan = ({ product, learnMore, isOfferDetails, isTablet }: { product: PersonalLoanProduct; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  const tickList: JSX.Element[] = []

  // secured loan
  if (product.securedLoan === "Y") {
    tickList.push(
      <TickLine key={"securedLoan"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Provide an asset for a lower interest rate
      </TickLine>,
    )
  } else {
    tickList.push(
      <TickLine key={"unsecuredLoan"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Unsecured loan
      </TickLine>,
    )
  }

  // fixed rate
  if (product.fixedRate === "Y") {
    tickList.push(
      <TickLine key={"fixedRate"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fixed rate and repayments
      </TickLine>,
    )
  } else {
    tickList.push(
      <TickLine key={"variableRate"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Variable interest rate
      </TickLine>,
    )
  }

  // minimum $0 establishment fee
  // if (!product.minUpfrontFee) {
  //   tickList.push(
  //     <TickLine key={"zeroEstablishmentFee"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
  //       No establishment fee
  //     </TickLine>
  //   )
  // }

  // high maximum loan amount
  if (product.maxLoanAmount && product.maxLoanAmount >= 75000) {
    tickList.push(
      <TickLine key={"highLoanMaximum"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Loan amounts up to ${product.maxLoanAmount.toLocaleString("en-US")}
      </TickLine>,
    )
  }

  return <TickLines product={product} tickList={tickList} learnMore={learnMore} isOfferDetails={isOfferDetails} isTablet={isTablet} />
}

export const HomeLoan = ({ product, rateId, learnMore, isOfferDetails, isTablet, setFilter, isTopPick }: { product: HomeLoanProduct; rateId: string; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean; setFilter?: boolean; isTopPick?: boolean }) => {
  // TickList Hierarchy
  const tickList = []

  const provider = AppManager.getInstance().getReducedHomeLoanProviderById(product.providerId.toString())
  let hlPack = null
  if (product.isPackage === "Y") {
    for (let i = 0; i < provider.hlPackages.length; i++) {
      if (provider.hlPackages[i].packageId === product.packageId) {
        hlPack = provider.hlPackages[i]
        break
      }
    }
  }

  // Fee
  if ((product.accountFee === 0 || product.accountFee === null) && (hlPack === null || hlPack.packageFee === 0)) {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        No ongoing fees
      </TickLine>,
    )
  }

  // Split Loan
  if (product.splitLoan === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Split into fixed and variable portions
      </TickLine>,
    )
  }

  // Offset
  if (product.id.charAt(8) === "0" && product.offsetVariable === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Offset
      </TickLine>,
    )
  } else if (product.id.charAt(8) === "1" && product.offsetFixed === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Offset
      </TickLine>,
    )
  } else if (product.id.charAt(8) === "2") {
    if (product.offsetVariable === "Y" && product.offsetFixed === "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Offset variable and fixed rates
        </TickLine>,
      )
    } else if (product.offsetVariable === "Y" && product.offsetFixed !== "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Offset variable rates
        </TickLine>,
      )
    } else if (product.offsetVariable !== "Y" && product.offsetFixed === "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Offset fixed rates
        </TickLine>,
      )
    }
  }

  // Add Repayments
  if (product.addRepayments === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Make extra repayments
      </TickLine>,
    )
  }

  // Redraw
  if (product.id.charAt(8) === "0" && product.redrawVariable === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Redraw
      </TickLine>,
    )
  } else if (product.id.charAt(8) === "1" && product.redrawFixed === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Redraw
      </TickLine>,
    )
  } else if (product.id.charAt(8) === "2") {
    if (product.redrawVariable === "Y" && product.redrawFixed === "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Redraw from variable and fixed rates
        </TickLine>,
      )
    } else if (product.redrawVariable === "Y" && product.redrawFixed !== "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Redraw from variable rates
        </TickLine>,
      )
    } else if (product.redrawVariable !== "Y" && product.redrawFixed === "Y") {
      tickList.push(
        <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
          Redraw from fixed rates
        </TickLine>,
      )
    }
  }

  // Is Package
  if (product.isPackage === "Y") {
    tickList.push(
      <TickLine key={"lowAnnual"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Package discounts and benefits
      </TickLine>,
    )
  }

  return <TickLines product={product} tickList={tickList} learnMore={learnMore} descriptiveText={product.nameLong} rateId={rateId} isOfferDetails={isOfferDetails} isTablet={isTablet} setFilter={setFilter} isTopPick={isTopPick} />
}

export const TermDeposit = ({ product, rateId, learnMore, isOfferDetails, isTablet, setFilter, isTopPick }: { product: TermDepositProduct; rateId?: string; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean; setFilter?: boolean; isTopPick?: boolean }) => {
  // TickList Hierarchy
  const tickList = []

  // Fee
  if (product.earlyWithdrawalFee && product.earlyWithdrawalFee === 0) {
    tickList.push(
      <TickLine key={"earlyWithdrawal"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        No early withdrawal fee
      </TickLine>,
    )
  }

  // Maturity
  if (product.getApplicationFrequenciesAvailable().indexOf(-1) >= 0) {
    tickList.push(
      <TickLine key={"maturityRepayment"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Interest paid at maturity
      </TickLine>,
    )
  }

  return <TickLines product={product} tickList={tickList} learnMore={learnMore} descriptiveText={product.nameLong} rateId={rateId} isOfferDetails={isOfferDetails} isTablet={isTablet} setFilter={setFilter} isTopPick={isTopPick} />
}

export const TransactionAccount = ({ product, rateId, learnMore, isOfferDetails, isTablet, setFilter, isTopPick }: { product: AccountProduct; rateId?: string; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean; setFilter?: boolean; isTopPick?: boolean }) => {
  // TickList Hierarchy
  const tickList = []

  // Fee
  if (product.noFees()) {
    tickList.push(
      <TickLine key={"noMonthlyFee"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        No monthly fee
      </TickLine>,
    )
  }

  // Fee Waivers

  // Balance and deposit
  if (product.afDepositWaiver && product.afDepositWaiver > 0) {
    tickList.push(
      <TickLine key={"depositWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for depositing
      </TickLine>,
    )
  }
  if (product.afBalanceWaiver && product.afBalanceWaiver > 0) {
    tickList.push(
      <TickLine key={"balanceWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for account balance
      </TickLine>,
    )
  }

  // Student and youth
  if (product.afStudentWaiver === "Y" && product.afMaxAgeWaiver) {
    tickList.push(
      <TickLine key={"studentWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for under {product.afMaxAgeWaiver}, Students
      </TickLine>,
    )
  } else if (product.afStudentWaiver !== "Y" && product.afMaxAgeWaiver) {
    tickList.push(
      <TickLine key={"studentWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for under {product.afMaxAgeWaiver}
      </TickLine>,
    )
  } else if (product.afStudentWaiver === "Y" && !product.afMaxAgeWaiver) {
    tickList.push(
      <TickLine key={"studentWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for Students
      </TickLine>,
    )
  }

  // Concession and elderly
  if (product.afConcessionWaiver === "Y" && product.afMinAgeWaiver) {
    tickList.push(
      <TickLine key={"pensionerWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for over {product.afMinAgeWaiver}, eligible concession cards
      </TickLine>,
    )
  } else if (product.afConcessionWaiver !== "Y" && product.afMinAgeWaiver) {
    tickList.push(
      <TickLine key={"pensionerWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for over {product.afMinAgeWaiver}
      </TickLine>,
    )
  } else if (product.afConcessionWaiver === "Y" && !product.afMinAgeWaiver) {
    tickList.push(
      <TickLine key={"pensionerWaiver"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Fee waiver for eligible concession cards
      </TickLine>,
    )
  }

  // Interest
  const maxInterestRate = product.getMaxInterestRate("STANDARD")
  if (maxInterestRate && maxInterestRate.interestRate > 0) {
    tickList.push(
      <TickLine key={"earnInterest"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        Earn interest on your balance
      </TickLine>,
    )
  }

  // Foreign transaction fee
  if (!product.fxFee || product.fxFee === 0) {
    tickList.push(
      <TickLine key={"noFXFee"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        No international transaction fees
      </TickLine>,
    )
  }

  // TODO: rewards

  return <TickLines product={product} tickList={tickList} learnMore={learnMore} descriptiveText={product.nameLong} rateId={rateId} isOfferDetails={isOfferDetails} isTablet={isTablet} setFilter={setFilter} isTopPick={isTopPick} />
}

export const SavingsAccount = ({ product, rateId, learnMore, isOfferDetails, isTablet, setFilter, isTopPick }: { product: AccountProduct; rateId?: string; learnMore?: boolean; isOfferDetails?: boolean; isTablet?: boolean; setFilter?: boolean; isTopPick?: boolean }) => {
  // TickList Hierarchy
  const tickList = []

  // Fee
  if (product.noFees()) {
    tickList.push(
      <TickLine key={"noMonthlyFee"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        No monthly fee
      </TickLine>,
    )
  }

  // Ages
  if (product.minAge || product.maxAge) {
    tickList.push(
      <TickLine key={"ageRange"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        For ages {product.minAge ? (product.maxAge ? `${product.minAge} to ${product.maxAge}` : `${product.minAge} and over`) : product.maxAge ? `${product.maxAge} and under` : ""}
      </TickLine>,
    )
  }

  // Student
  if (product.forStudents) {
    tickList.push(
      <TickLine key={"forStudents"} isOfferDetails={isOfferDetails} isTablet={isTablet}>
        For students
      </TickLine>,
    )
  }

  // TODO: rewards

  return <TickLines product={product} tickList={tickList} learnMore={learnMore} descriptiveText={product.nameLong} rateId={rateId} isOfferDetails={isOfferDetails} isTablet={isTablet} setFilter={setFilter} isTopPick={isTopPick} />
}

/**
 * Template that renders the tick lines list component
 * @param props
 * @returns JSX Element, list of tick lines
 */
const TickLines = ({ product, tickList, learnMore, descriptiveText, rateId, isOfferDetails, isTablet, isArticle, setFilter, isTopPick }: { product: Product; tickList: JSX.Element[]; learnMore?: boolean; descriptiveText?: string; rateId?: string; isOfferDetails?: boolean; isTablet?: boolean; isArticle?: boolean; setFilter?: boolean; isTopPick?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  let finalLineMargin = tickList.length > 0 ? ("style" in tickList[tickList.length - 1].props ? tickList[tickList.length - 1].props.styles.marginBottom : "10px") : "10px"
  finalLineMargin = (parseInt(finalLineMargin.substring(0, 2)) - 11.5).toString() + "px"
  return (
    <div className={isTablet || !isWide ? styles.tickListContainerMobile : styles.tickListContainer} style={{ marginBottom: isArticle ? "0px" : isWide ? "50px" : "40px" }}>
      <div className={isOfferDetails || !isWide || isTablet ? styles.tickListMobile : styles.tickList}>{tickList}</div>
      {learnMore && isWide && !isTablet ? (
        <div style={{ width: "25%", position: "relative" }}>
          <Clickable.Text
            style={{ width: "100%", height: "56px", border: "none", position: tickList.length > 0 ? "absolute" : "relative", bottom: finalLineMargin, paddingRight: "20px", marginTop: tickList.length > 0 ? "0px" : "-19px" }}
            component={Link}
            to={`/${product.urlSlug}`}
            state={rateId ? { rateId } : null}
            onClick={() => {
              const actionData: any = { productId: product.id }
              if (product.forSale !== "Y") {
                actionData["forSale"] = product.forSale
              }
              if (rateId) {
                actionData["rateId"] = rateId
              }
              if (setFilter) {
                // set filter for top pick to show offer - Ubank Neat Home Loan
                const appManager = AppManager.getInstance()
                appManager.setHomeLoanAmount(500000)
                appManager.setLoanDeposit(40)
              }
              if (isTopPick) {
                DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_TOP_PICK_SELECTED, actionData)
              } else {
                DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PRODUCT_SELECTED, actionData)
              }
            }}
            secondary
            noBorder
            comp={1}
          >
            <p>
              Learn More<span style={{ display: "none" }}>{" about the " + descriptiveText}</span>
            </p>
          </Clickable.Text>
        </div>
      ) : null}
    </div>
  )
}

export default TickLines
