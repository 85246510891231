import * as Clickable from "components/clickable"
import { navigate } from "gatsby-link"
import React, { useEffect, useRef, useState } from "react"
import { apis as API } from "@sog/sdk"
import AppManager from "services/app-manager"
import SGTextfield from "../../textfield"
import * as styles from "./style.module.scss"

const Verify = props => {
  const storedMinutes = Number(localStorage.getItem("timerMinutes"))
  const storedSeconds = Number(localStorage.getItem("timerSeconds"))
  const initialMinutes = 1
  const initialSeconds = 0
  const [minutes, setMinutes] = useState(Number.isInteger(storedMinutes) ? storedMinutes : initialMinutes)
  const [seconds, setSeconds] = useState(Number.isInteger(storedSeconds) ? storedSeconds : initialSeconds)
  const [errorMsg, setErrorMsg] = useState()

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        localStorage.setItem("timerSeconds", String(seconds - 1))
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          localStorage.setItem("timerMinutes", String(minutes - 1))
          localStorage.setItem("timerSeconds", String(59))
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  const otpView = () => {
    const otp1 = useRef()
    const otp2 = useRef()
    const otp3 = useRef()
    const otp4 = useRef()
    const otp5 = useRef()
    const otp6 = useRef()
    const otpRefs = [otp1, otp2, otp3, otp4, otp5, otp6]
    const [isOtpValid, setIsOtpValid] = useState()

    const handleChange = i => {
      if (!otpRefs[i].current) {
        return
      }
      if (i === 5) {
        otpRefs[i].current.blur()
      } else {
        otpRefs[i + 1].current.focus()
      }
      if (otpRefs.every(o => o.current.value.length === 1)) {
        setIsOtpValid(true)
      } else {
        setIsOtpValid(false)
      }
    }

    const handleFocus = e => {
      e.target.value = ""
      setIsOtpValid(false)
    }

    const handleKeyDown = (e, i) => {
      if (e.keyCode === 8 && i > 0) {
        otpRefs[i - 1].current.focus()
      }
    }

    const handleResend = () => {
      API.sendOtpByEmail(props.location.state.email, responseJson => {
        localStorage.setItem("timerMinutes", String(1))
        localStorage.setItem("timerSeconds", String(0))
        setMinutes(1)
        setSeconds(0)
        setErrorMsg("")
      })
    }

    const handleSubmit = () => {
      var otpCode = ""
      otpRefs.forEach((r, i) => (otpCode += r.current.value))
      const data = {
        email: props.location.state.email,
        otpCode: otpCode,
      }
      API.verifyOtp(data, responseJson => {
        if (responseJson["is_verified"] === true) {
          AppManager.getInstance().setAuthenticatedUser(props.location.state.email)
          navigate("/offer-qa", {
            state: {
              providers: responseJson.providers,
            },
          })
        } else {
          setErrorMsg("Email or one-time password incorrect.")
        }
      })
    }

    return (
      <div>
        <div style={{ marginTop: "60px", fontFamily: "Arboria-Bold, sans-serif", marginBottom: "10px", fontSize: "16px" }}>One Time Password</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SGTextfield autoFocus className={styles.otpField} inputRef={otp1} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(0)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 0)} onPaste={e => e.preventDefault()} />
          <SGTextfield className={styles.otpField} inputRef={otp2} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(1)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 1)} onPaste={e => e.preventDefault()} />
          <SGTextfield className={styles.otpField} inputRef={otp3} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(2)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 2)} onPaste={e => e.preventDefault()} />
          <SGTextfield className={styles.otpField} inputRef={otp4} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(3)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 3)} onPaste={e => e.preventDefault()} />
          <SGTextfield className={styles.otpField} inputRef={otp5} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(4)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 4)} onPaste={e => e.preventDefault()} />
          <SGTextfield className={styles.otpField} inputRef={otp6} type="number" inputProps={{ maxLength: 1, min: 0, max: 9, style: { textAlign: "center", fontSize: "35px" } }} onChange={() => handleChange(5)} onFocus={e => handleFocus(e)} onKeyDown={e => handleKeyDown(e, 5)} onPaste={e => e.preventDefault()} />
        </div>
        <div style={{ marginTop: "60px", fontFamily: "Arboria-Book, sans-serif", marginBottom: "10px", fontSize: "16px" }}>
          Enter the one time password sent to your registered mobile.
          <div style={{ marginTop: "10px" }}>
            {errorMsg ? (
              <div>{errorMsg}</div>
            ) : minutes !== 0 || seconds !== 0 ? (
              <div>
                Expires in{" "}
                <span style={{ fontFamily: "Arboria-Bold, sans-serif" }}>
                  {minutes}:{seconds}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <Clickable.Text
          comp={3}
          className="survey-button"
          style={{
            height: "56px",
            width: "100%",
          }}
          onClick={() => {
            handleSubmit()
          }}
          disabled={!isOtpValid}
        >
          Continue
        </Clickable.Text>
        {errorMsg || (minutes === 0 && seconds === 0) ? (
          <Clickable.Text style={{ marginTop: "20px" }} secondary comp={3} className="survey-button" onClick={() => handleResend()}>
            Resend password
          </Clickable.Text>
        ) : null}
      </div>
    )
  }

  return (
    <div className="page-container2">
      <div className="survey-container">
        <div>
          <div style={{ marginTop: "50px", fontSize: "32px", fontFamily: "Arboria-Bold, sans-serif" }}>Login</div>
          {otpView()}
        </div>
      </div>
    </div>
  )
}

export default Verify
