import * as Clickable from "components/clickable"
import { navigate } from "gatsby-link"
import React, { useState } from "react"
import { apis as API } from "@sog/sdk"
import SGTextfield from "../../textfield"
import * as styles from "./style.module.scss"

export default function Login(props) {
  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(false)

  const onChangeEmail = e => {
    setEmail(e.target.value)
    setIsEmailValid(e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
  }

  const handleSubmit = () => {
    API.sendOtpByEmail(email, responseJson => {
    //  console.log(responseJson)
      localStorage.setItem("timerMinutes", String(1))
      localStorage.setItem("timerSeconds", String(0))
      navigate("/offer-qa/verify", {
        state: {
          email,
        },
      })
    })
  }

  const usernameView = () => {
    return (
      <div>
        <div style={{ marginTop: "60px", marginBottom: "150px" }}>
          <SGTextfield className={styles.tf} id="outlined-basic" label="Email address" autoComplete="email" onChange={onChangeEmail} />
        </div>
        <Clickable.Text
          comp={3}
          className="survey-button"
          style={{
            height: "56px",
            width: "100%",
          }}
          onClick={() => {
            handleSubmit()
          }}
          disabled={!isEmailValid}
        >
          Continue
        </Clickable.Text>
      </div>
    )
  }

  return (
    <div className="page-container2">
      <div className="survey-container">
        <div>
          <div style={{ marginTop: "50px", fontSize: "32px", fontFamily: "Arboria-Bold, sans-serif" }}>Login</div>
          {usernameView()}
        </div>
      </div>
    </div>
  )
}
