import { Router } from "@reach/router"
import Layout from "components/layout"
import NotFound from "components/not-found"
import Login from "components/offer-qa/login"
import OfferDetails from "components/offer-qa/offer-details"
import Offers from "components/offer-qa/offers"
import SelectCard from "components/offer-qa/select-card"
import Verify from "components/offer-qa/verify"
import React from "react"

const OfferQABase = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default function OfferQA(props) {
  return (
    <Layout>
      <Router basepath="/offer-qa" primary={false}>
        <OfferQABase path="/" component={SelectCard} />
        <OfferQABase path="/login" component={Login} />
        <OfferQABase path="/verify" component={Verify} />
        <OfferQABase path="/offers" component={Offers} />
        <OfferQABase path="/offer-details" component={OfferDetails} />
        <NotFound default />
      </Router>
    </Layout>
  )
}
